
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

interface Filter {
  invoice_number: string;
  dealer_number: string;
  vat_id: string;
  shipment_id: string;
  track_number: string;
  sale_order_number: string;
  brand_article_no: string;
  created_at_start: string;
  created_at_end: string;
}
const options = ref({
  status: [
    {
      label: "Pending",
      value: "Pending",
    },
    {
      label: "Paid",
      value: "Paid",
    },
    {
      label: "Canceled",
      value: "Canceled",
    },
  ],
  customer: [],
});

export default defineComponent({
  name: "filter-sales-invoice-dropdown",
  components: {},
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);
    const formData = ref<Filter>({
      invoice_number: "",
      dealer_number: "",
      vat_id: "",
      shipment_id: "",
      track_number: "",
      sale_order_number: "",
      brand_article_no: "",
      created_at_start: "",
      created_at_end: "",
    });

    const submit = () => {
      // console.log(
      //   moment(formData.value.order_date).format("DD.MM.YYYY HH:mm:ss")
      // );
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    return {
      t,
      formData,
      formRef,
      submit,
      handleReset,
      options,
    };
  },
});
